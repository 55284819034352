<template>
  <div class="editAddress">
    <van-address-edit
      save-button-text="确认修改"
      show-area
      :address-info="addressInfo"
      :area-list="areaList"
      :search-result="searchResult"
      @save="onSave"
    />
  </div>
</template>

<script>
import { areaList } from '@vant/area-data';

export default {
  data() {
    return {
      areaList,
      searchResult: [],
    };
  },
  computed: {
    addressInfo() {
      return {
        ...this.$store.state.addressInfo,
        areaCode: this.$store.state.addressInfo.areaCode,
        tel: this.$store.state.addressInfo.phone
      }
    }
  },
  created() {
    const value = this.findKey(areaList.county_list, this.addressInfo.county)
    this.addressInfo.areaCode = value
    this.$store.commit('setAddressInfo', this.addressInfo)
  },
  methods: {
    findKey(obj, value, compare = (a, b) => a === b) {
      return Object.keys(obj).find(k => compare(obj[k], value))
    },
    onSave(e) {
      let value = {...e, phone: e.tel}
      this.$store.commit('setAddressInfo', value)
      this.$router.back(-1)
    },
  },
};
</script>

<style>
.van-button--danger {
  color: #fff;
  border: none;
  background: linear-gradient(89deg, rgba(253, 110, 5, 0.66) 10%, #fd6e05 81%);
}
</style>